import { Button } from 'primereact/button';
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import useScanDetection from 'use-scan-detection';
import { get_Products } from '../../../../Apis/productsApi/ProductsApi';
import {
   Create_Products_Stock,
   Get_Details_Products_Stock,
   Put_Update_Products_Stock
} from '../../../../Apis/productsStockApi/ProductTagMasterStockApi';
import { get_Store_For_Add_Update } from '../../../../Apis/storeApi/StoreApi';
import { REDIRECT_PATH } from '../../../../Routes';
import {
   ADD_MINUS,
   MAX_STOCK,
   MIN_STOCK,
   NOTE,
   QTY,
   StoreName,
   TYPE,
   TYPE_FELDS,
   Total_Qty
} from '../../../../constants';
import ProductsStockContext from '../../../../context/ProductsStockContext/ProductsStockContext';
import Breadcrumb from '../../../../reuseable/Breadcrumb/Breadcrumb';
import { PRODUCT, PRODUCT_ADD, SLACE } from '../../../../reuseable/Breadcrumb/BreadcrumbConstant';
import { FormDataFeild } from '../../../../reuseable/Inputs';
import { BackButton } from '../../../../reuseableComponents/buttons';
import { IsLoadingResponse } from '../../../../utils/Helper';

const ProductsStockCreateFormPopup = () => {
   const { setFormData, id, isEdit, formData, setErrors, setId, setIsEdit } = useContext(ProductsStockContext);
   const history = useNavigate();
   const [filterDrop, setFilterDrop] = useState({
      productFilter: '',
      storeFilter: '',
      skuCode: ''
   });

   let { stockId, pos } = useParams();

   useScanDetection({
      onComplete: (e) => {
         setFilterDrop((prev) => {
            return { ...prev, skuCode: e };
         });
      },
      minLength: 3
   });

   /**
    *
    * @param {event } e
    * create product stock & update product stock
    */

   const { mutate: createProductsStock, isLoading: createProductsStockLoader } = useMutation(
      (formData) => Create_Products_Stock(formData, history, setFormData, pos),
      {
         onSuccess: (res) => {
            if (res.status === 200) {
               setFilterDrop({
                  productFilter: '',
                  storeFilter: '',
                  skuCode: ''
               });
            }
         }
      }
   );

   const { mutate: updateProductsStock, isLoading: updateProductsStockLoader } = useMutation(
      (formData) => Put_Update_Products_Stock(formData, id, history, setFormData, pos),
      {
         onSuccess: (res) => {
            if (res.status === 200) {
               setFilterDrop({
                  productFilter: '',
                  storeFilter: '',
                  skuCode: ''
               });
            }
         }
      }
   );

   const handleProductsStockCreateAndUpdate = async (index) => {
      try {
         debugger;
         if (stockId !== 'add' && stockId !== -1 && isEdit === false && stockId) {
            let newData = {
               productId: formData.products[index]?.productId,
               stocks: formData.products[index]?.stocks.map((elem) => {
                  return {
                     storeId: elem.storeId,
                     qty: elem?.qty ? elem?.qty : 0,
                     minStock: elem?.minStock ? elem?.minStock : 0,
                     maxStock: elem?.minStock ? elem?.minStock : 0,
                     note: ''
                  };
               })
            };

            updateProductsStock(newData);
         } else {
            let newData = {
               productId: formData.products[index]?.productId,
               stocks: formData.products[index]?.stocks.map((elem) => {
                  return {
                     storeId: elem?.storeId,
                     qty: elem?.qty ?? 0,
                     minStock: elem?.minStock ?? 0,
                     maxStock: elem?.maxStock ?? 0,
                     type: elem?.type ? +elem.type.code : 1,
                     totalQty: elem?.totalQty ?? null,
                     note: elem?.note ?? null
                  };
               })
            };

            createProductsStock(newData);
         }
      } catch (error) {
         const validationErrors = {};
         error.inner.forEach((validationError) => {
            validationErrors[validationError.path] = validationError.message;
         });
         setErrors(validationErrors);
         if (Object.keys(validationErrors)[0]) {
            const inputField = document.getElementsByName(Object.keys(validationErrors)[0]);
            inputField[0].focus();
         }
      }
   };

   /**
    *
    * @param {get {value} and {name}} e
    * input value change
    */

   const handleChangeMultipleStock = (e, index, i) => {
      const { name, value } = e.target;
      let old = [...formData.products];
      console.log('old >>', old);
      console.log('name >>', name);
      debugger;
      if (name === 'type') {
         if (+value?.code === 1) {
            // 1 = credit
            let subValue = 0;
            subValue = +old[index].stocks[i].copyTotalQty + +old[index].stocks[i].qty;
            old[index].stocks[i]['totalQty'] = subValue;
         } else if (+value?.code === 2) {
            // 2 = debit
            let subValue = 0;
            subValue = +old[index].stocks[i].copyTotalQty - +old[index].stocks[i].qty;
            old[index].stocks[i]['totalQty'] = Math?.max(subValue, 0);
         } else if (value === undefined || value === null || value === '') {
            old[index].stocks[i]['totalQty'] = old[index].stocks[i].copyTotalQty;
         }
         old[index].stocks[i][name] = value;
      } else if (
         name === 'qty' &&
         (+old[index].stocks[i]?.type?.code === 1 || +old[index].stocks[i]?.type?.code === 2)
      ) {
         if (+old[index].stocks[i]?.type?.code === 1) {
            let subValue = 0;
            if (value !== undefined) {
               subValue = +old[index].stocks[i].copyTotalQty + +value;
               old[index].stocks[i]['totalQty'] = subValue;
            } else {
               old[index].stocks[i]['totalQty'] = +old[index].stocks[i].copyTotalQty;
            }
         } else if (+old[index].stocks[i]?.type?.code === 2) {
            let subValue = 0;
            if (old[index].stocks[i].copyTotalQty === 0) {
               old[index].stocks[i]['totalQty'] = +old[index].stocks[i].copyTotalQty;
            } else {
               subValue = +old[index].stocks[i].copyTotalQty - +value;
               old[index].stocks[i]['totalQty'] = subValue;
            }
         }

         if (value >= 0) {
            old[index].stocks[i][name] = value;
         } else {
            old[index].stocks[i][name] = '';
         }
      } else if (name === 'qty') {
         if (value >= 0) {
            old[index].stocks[i][name] = value;
         } else {
            old[index].stocks[i][name] = '';
         }
      } else {
         old[index].stocks[i][name] = value;
      }
      setFormData({ ...formData, products: old });
   };

   /**
    * if stockId is available to  refresh page to field fill api call
    */
   const { mutate: getProductStockDetails } = useMutation((stockId) =>
      Get_Details_Products_Stock(stockId, history, setFormData)
   );

   useEffect(() => {
      let valueOfNumber = Number(stockId);
      if (stockId !== 'add' && typeof valueOfNumber === 'number') {
         getProductStockDetails(stockId);
         setId(stockId);
         setIsEdit(false);
      }
      // eslint-disable-next-line
   }, [stockId]);

   /**
    * get product and store dropdown data api call
    */

   const paramsObj = useMemo(() => {
      let params = {
         showAll: true,
         search: { productName: filterDrop.productFilter }
      };

      if (filterDrop.skuCode) {
         params = {
            ...params,
            search: { ...params.search, filter: filterDrop.skuCode }
         };
      }
      return params;
   }, [filterDrop.productFilter, filterDrop.skuCode]);

   useQuery(['getProductDrop', paramsObj], async () => await get_Products(paramsObj), {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: async (res) => {
         if (res.status === 200 && (filterDrop.productFilter || filterDrop.skuCode || res.payload.data.length === 1)) {
            const productsPremies = res.payload.data.map(async (elem) => {
               const stocks = await get_Store_For_Add_Update({ showAll: true, productId: elem.productId });
               return {
                  productId: elem.productId,
                  productName: elem.productName,
                  stocks: stocks.payload.data.map((elem) => {
                     return {
                        storeId: elem.storeId,
                        storeName: elem.name,
                        qty: 0,
                        minStock: elem?.productStocks[0]?.minStock,
                        maxStock: elem?.productStocks[0]?.maxStock,
                        type: { code: '1', name: '(+) ADD' } ?? null,
                        totalQty: elem?.productStocks[0]?.qty ?? 0,
                        note: elem?.productStocks[0]?.note ?? null,
                        copyTotalQty: elem?.productStocks[0]?.qty ?? 0
                     };
                  })
               };
            });

            const products = await Promise.all(productsPremies);
            setFormData((prev) => {
               return {
                  ...prev,
                  products: products
               };
            });
         }
      }
   });

   const { mutate: storeDropFunc } = useMutation((paramsStoreObj) =>
      get_Store_For_Add_Update(paramsStoreObj, setFormData)
   );

   useMemo(() => {
      if (formData.productId?.code) {
         let paramsStoreObj = {
            showAll: true,
            productId: formData.productId?.code
         };
         storeDropFunc(paramsStoreObj);
      }
      // eslint-disable-next-line
   }, [formData.productId?.code]);

   /**
    * go to parent page
    */
   const redirect = () => {
      const path = pos == 'true' ? REDIRECT_PATH.POS : REDIRECT_PATH.PRODUCTS_STOCK;
      history(path);
      setFormData({ productId: '', stocks: [] });
   };
   return (
      <Fragment>
         <Breadcrumb name={PRODUCT} slace={SLACE} name1={PRODUCT_ADD} />
         <IsLoadingResponse isLoading={createProductsStockLoader || updateProductsStockLoader} />
         <BackButton
            history={history}
            path={pos == 'true' ? REDIRECT_PATH.POS : REDIRECT_PATH.PRODUCTS_STOCK}
            otherFunc={redirect}
         />
         <div className='card-div'>
            <Row>
               <Col sm='12'>
                  <Card>
                     <CardBody>
                        <Row>
                           <Col md='12 mb-4'>
                              <FormDataFeild
                                 label={'Product'}
                                 name='productId'
                                 id='productId'
                                 placeholder={'Search product for stock add...'}
                                 value={filterDrop.productFilter}
                                 handleChange={(e) =>
                                    setFilterDrop((prev) => {
                                       return {
                                          ...prev,
                                          productFilter: e.target.value
                                       };
                                    })
                                 }
                              />
                           </Col>
                           {formData?.products?.map((elem, index) => {
                              return (
                                 <div className='border-bottom'>
                                    <div className='flex justify-content-between flex-wrap gap-2 align-items-center'>
                                       <h3 className='my-4'>Product :- {elem.productName}</h3>
                                       <Button
                                          label='Stock Add'
                                          severity='warning'
                                          style={{ height: '40px', width: '100%' }}
                                          type='button'
                                          onClick={() => handleProductsStockCreateAndUpdate(index)}
                                          className='w-auto'
                                       />
                                    </div>
                                    {elem.stocks.map((stock, i) => (
                                       <Row>
                                          <Col md='2 mb-4'>
                                             <FormDataFeild
                                                label={StoreName}
                                                name='storeName'
                                                id='storeName'
                                                type={TYPE_FELDS.text}
                                                placeholder={StoreName}
                                                value={stock.storeName}
                                                handleChange={(e) => handleChangeMultipleStock(e, index, i)}
                                                disabled={true}
                                             />
                                          </Col>
                                          <Col md={`${pos !== 'true' ? '1' : '2'} mb-4`}>
                                             <FormDataFeild
                                                label={QTY}
                                                name='qty'
                                                id='qty'
                                                type={TYPE_FELDS.number}
                                                placeholder={QTY}
                                                value={stock.qty}
                                                handleChange={(e) => handleChangeMultipleStock(e, index, i)}
                                             />
                                          </Col>
                                          {pos !== 'true' && (
                                             <Col md='2 mb-4'>
                                                <FormDataFeild
                                                   label={ADD_MINUS}
                                                   name='type'
                                                   id='type'
                                                   placeholder={ADD_MINUS}
                                                   value={stock.type}
                                                   handleChange={(e) => handleChangeMultipleStock(e, index, i)}
                                                   dropdown={true}
                                                   option={TYPE}
                                                   filter={false}
                                                />
                                             </Col>
                                          )}
                                          <Col md={`${pos !== 'true' ? '1' : '2'} mb-4`}>
                                             <FormDataFeild
                                                label={Total_Qty}
                                                name='totalQty'
                                                id='totalQty'
                                                type={TYPE_FELDS.number}
                                                placeholder={Total_Qty}
                                                value={stock.totalQty}
                                                handleChange={(e) => handleChangeMultipleStock(e, index, i)}
                                                disabled={true}
                                             />
                                          </Col>
                                          <Col md='1 mb-4'>
                                             <FormDataFeild
                                                label={MIN_STOCK}
                                                name='minStock'
                                                id='minStock'
                                                type={TYPE_FELDS.number}
                                                placeholder={MIN_STOCK}
                                                value={stock.minStock}
                                                handleChange={(e) => handleChangeMultipleStock(e, index, i)}
                                             />
                                          </Col>
                                          <Col md='1 mb-4'>
                                             <FormDataFeild
                                                label={MAX_STOCK}
                                                name='maxStock'
                                                id='maxStock'
                                                type={TYPE_FELDS.number}
                                                placeholder={MAX_STOCK}
                                                value={stock.maxStock}
                                                handleChange={(e) => handleChangeMultipleStock(e, index, i)}
                                             />
                                          </Col>
                                          <Col md='4 mb-4'>
                                             <FormDataFeild
                                                label={NOTE}
                                                name='note'
                                                id='note'
                                                type={TYPE_FELDS.text}
                                                placeholder={NOTE}
                                                value={stock.note}
                                                handleChange={(e) => handleChangeMultipleStock(e, index, i)}
                                             />
                                          </Col>
                                       </Row>
                                    ))}
                                 </div>
                              );
                           })}

                           {/* <Col md="4 mb-4">
                      <FormDataFeildTextArea
                        label={NOTE}
                        name="note"
                        id="note"
                        type={TYPE_FELDS.text}
                        placeholder={NOTE}
                        value={formData.note}
                        handleChange={handleChangeStock}
                      />
                    </Col> */}
                        </Row>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </div>
      </Fragment>
   );
};

export default ProductsStockCreateFormPopup;
