import * as Yup from 'yup';
import { SLOT_OFF_TIME } from '../constants';

export const createRoomSingle = () => {
   return Yup.object().shape({
      roomName: Yup.string().required('Room name is required'),
      guestHouseId: Yup.object().required('Guest house ID is required'),
      address: Yup.string().required('Address is required'),
      categoryId: Yup.object().required('Category ID is required'),
      capacity: Yup.number().required('Capacity is required'),
      extraBed: Yup.number().required('Extra bed is required'),
      facilities: Yup.string().required('Facilities are required')
   });
};

export const createRoomBulk = () => {
   return Yup.object().shape({
      guestHouseId: Yup.object().required('Guest house ID is required'),
      address: Yup.string().required('Address is required'),
      categoryId: Yup.object().required('Category ID is required'),
      capacity: Yup.number().required('Capacity is required'),
      extraBed: Yup.number().required('Extra bed is required'),
      facilities: Yup.string().required('Facilities are required'),
      to: Yup.string().required('To are required'),
      from: Yup.string().required('From are required')
   });
};

export const createBrandMasterSchema = () => {
   return Yup.object().shape({
      brandName: Yup.string().required('Brand Name is required'),
      brandDesc: Yup.string().required('Brand Description is required'),
      slugUrl: Yup.string().required('SlugUrl is required')
   });
};

export const createUserSchema = () => {
   return Yup.object().shape({
      name: Yup.string().required('Name is required'),
      dialCode: Yup.object().required('Dial Code is required'),
      phone: Yup.number().required('Number is required'),
      email: Yup.string().email().required('Email is required'),
      roleId: Yup.object().required('Role Id is required'),
      // assignStores: Yup.array().required('Store is required'),
      password: Yup.string().required('Password is required'),
      confirmPassword: Yup.string().required('Confirm Password is required')
   });
};

export const createUserPasswordSchema = () => {
   return Yup.object().shape({
      name: Yup.string().required('Name is required'),
      dialCode: Yup.object().required('Dial Code is required'),
      phone: Yup.number().required('Number is required'),
      email: Yup.string().email().required('Email is required'),
      roleId: Yup.object().required('Role Id is required')
      // assignStores: Yup.array().required('Store is required')
   });
};

export const createCustomerSchema = () => {
   return Yup.object().shape({
      name: Yup.string().required('Name is required'),
      pincode: Yup.string().required('Post code is required')
      // assignStores: Yup.array().required('Store is required')
   });
};

export const createProductSchema = () => {
   return Yup.object().shape({
      productName: Yup.string().required('Product Name is required'),
      brandId: Yup.object().required('Brand is required'),
      catId: Yup.object().required('Category is required'),
      taxType: Yup.object().required('Tax type is required'),
      slugUrl: Yup.string().required('Slug is required'),
      mrp: Yup.string().required('MRP is required'),
      sellPrice: Yup.number().required('Selling Price is required'),
      sortDesc: Yup.string().required('Short Description is required'),
      longDesc: Yup.string().required('Long Description is required'),
      sku: Yup.string().required('SKU code is required')
   });
};

export const createProductStockSchema = () => {
   return Yup.object().shape({
      productId: Yup.string().required('Product is required')
   });
};

export const createCategorySchema = () => {
   return Yup.object().shape({
      name: Yup.string().required('Name is required'),
      slugUrl: Yup.string().required('Slug is required'),
      seoTitle: Yup.string().required('SEO Title is required'),
      seoDesc: Yup.string().required('SEO Description is required'),
      seoKeyword: Yup.string().required('SEO Keyword is required')
   });
};

export const createStoreSchema = () => {
   return Yup.object().shape({
      name: Yup.string().required('Name is required'),
      assignUsers: Yup.array().required('User is required'),
      address: Yup.string().required('Address is required'),
      latitude: Yup.number().required('Latitude is required'),
      longitude: Yup.number().required('Longitude is required')
   });
};

export const createDeliverySlotSchema = () => {
   return Yup.object().shape({
      slotName: Yup.string().required('Slot Name is required'),
      startTime: Yup.string().required('start Time is required'),
      endTime: Yup.string().required('End Time is required'),
      order: Yup.number().required('Order is required'),
      storeId: Yup.object().required('Store is required')
   });
};

export const createBannerSliderSchema = (typeData) => {
   if (typeData === '1') {
      return Yup.object().shape({
         type: Yup.object().required('Type is required'),
         name: Yup.string().required('Name is required'),
         productId: Yup.object().required('Product is required'),
         image: Yup.string().required('image is required')
      });
   } else {
      return Yup.object().shape({
         type: Yup.object().required('Type is required'),
         name: Yup.string().required('Name is required'),
         catId: Yup.object().required('Category is required'),
         image: Yup.string().required('image is required')
      });
   }
};

export const createOrderSettingSchema = () => {
   return Yup.object().shape({
      taxLabel: Yup.string().required('Tax Label is required'),
      numberOfImagesPerProduct: Yup.number().required('Image per product is required'),
      defaultTimeZone: Yup.object().required('Default time zone is required'),
      defaultDateFormat: Yup.object().required('Default date format is required')
   });
};

export const createProductsTagSchema = () => {
   return Yup.object().shape({
      tagName: Yup.string().required('Tag Name is required'),
      tagColor: Yup.string().required('Tag color is required')
   });
};

export const createDetailsPageSchema = (hyperLink) => {
   if (hyperLink !== '' && hyperLink !== undefined && hyperLink !== null) {
      return Yup.object().shape({
         title: Yup.string().required('title is required'),
         order: Yup.string().required('order is required'),
         pageUrl: Yup.string().required('pageUrl is required'),
         seoTitle: Yup.string().required('seoTitle is required'),
         seoKeyword: Yup.string().required('seoKeyword is required'),
         seoDesc: Yup.string().required('seoDesc is required'),
         hyperLink: Yup.string().required('hyper link is required'),
         detailsMasterId: Yup.object().required('details heading is required')
      });
   } else {
      return Yup.object().shape({
         title: Yup.string().required('title is required'),
         order: Yup.string().required('order is required'),
         pageUrl: Yup.string().required('pageUrl is required'),
         seoTitle: Yup.string().required('seoTitle is required'),
         seoKeyword: Yup.string().required('seoKeyword is required'),
         seoDesc: Yup.string().required('seoDesc is required'),
         description: Yup.string().required('description is required'),
         detailsMasterId: Yup.object().required('details heading is required')
      });
   }
};

export const createDetailsMasterPageSchema = () => {
   return Yup.object().shape({
      title: Yup.string().required('title is required'),
      order: Yup.string().required('order is required')
   });
};

export const createFeatureSchema = () => {
   return Yup.object().shape({
      title: Yup.string().required('title is required'),
      subTitle: Yup.string().required('subTitle is required'),
      image: Yup.string().required('image is required'),
      order: Yup.string().required('order is required')
   });
};

export const createDeliveryOffDaySchema = ({ slotOfType }) => {
   if (slotOfType === SLOT_OFF_TIME.SPECIFIC) {
      return Yup.object().shape({
         offDate: Yup.string().required('Off date is required'),
         slotId: Yup.object().required('Slot is required'),
         slotOffType: Yup.object().required('Slot off type is required'),
         storeId: Yup.object().required('Store is required')
      });
   } else {
      return Yup.object().shape({
         offDate: Yup.string().required('Off date is required'),
         slotOffType: Yup.object().required('Slot off type is required'),
         storeId: Yup.object().required('Store is required')
      });
   }
};

export const createRiderSchema = () => {
   return Yup.object().shape({
      assignStores: Yup.object().required('Store is required'),
      dialCode: Yup.object().required('Dial Code is required'),
      phone: Yup.number().required('Number is required'),
      deviceId: Yup.string().required('Device Id is required'),
      name: Yup.string().required('Name is required'),
      vehicleNumber: Yup.string().required('Vehicle Number is required'),
      vehicleType: Yup.string().required('Vehicle Type is required'),
      email: Yup.string().email().required('Email is required'),
      address: Yup.string().required('Address is required'),
      city: Yup.string().required('City is required'),
      password: Yup.string().required('Password is required'),
      confirmPassword: Yup.string().required('Confirm Password is required')
   });
};

export const createRiderPasswordSchema = () => {
   return Yup.object().shape({
      assignStores: Yup.object().required('Store is required'),
      dialCode: Yup.object().required('Dial Code is required'),
      phone: Yup.number().required('Number is required'),
      name: Yup.string().required('Name is required'),
      deviceId: Yup.string().required('Device Id is required'),
      vehicleNumber: Yup.string().required('Vehicle Number is required'),
      vehicleType: Yup.string().required('Vehicle Type is required'),
      email: Yup.string().email().required('Email is required'),
      address: Yup.string().required('Address is required'),
      city: Yup.string().required('City is required')
   });
};

export const createPromoCodeSchema = (typeData) => {
   if (typeData === '3') {
      return Yup.object().shape({
         assignStores: Yup.object().required('Store is required'),
         promoCode: Yup.string().required('Promo Code is required'),
         applyType: Yup.object().required('Apply Type is required'),
         productId: Yup.object().required('Product is required'),
         discountType: Yup.object().required('Discount Type is required'),
         discountValue: Yup.string().required('Discount Value is required')
      });
   } else if (typeData === '2') {
      return Yup.object().shape({
         assignStores: Yup.object().required('Store is required'),
         promoCode: Yup.string().required('Promo Code is required'),
         applyType: Yup.object().required('Apply Type is required'),
         catId: Yup.object().required('Category is required'),
         discountType: Yup.object().required('Discount Type is required'),
         discountValue: Yup.string().required('Discount Value is required')
      });
   } else {
      return Yup.object().shape({
         assignStores: Yup.object().required('Store is required'),
         promoCode: Yup.string().required('Promo Code is required'),
         applyType: Yup.object().required('Apply Type is required'),
         discountType: Yup.object().required('Discount Type is required'),
         discountValue: Yup.string().required('Discount Value is required')
      });
   }
};
export const createDealOfTheDaySchema = (typeData) => {
   return Yup.object().shape({
      assignStores: Yup.object().required('Store is required'),
      productId: Yup.object().required('Product is required'),
      discountType: Yup.object().required('Discount Type is required'),
      discountValue: Yup.string().required('Discount Value is required')
   });
};

export const createAttributeMasterSchema = () => {
   return Yup.object().shape({
      name: Yup.string().required('Attribute Name is required'),
      attributeType: Yup.object().required('Attribute Type is required'),
      order: Yup.string().required('SlugUrl is required')
   });
};
export const createAttributeSchema = () => {
   return Yup.object().shape({
      value: Yup.string().required('Value is required'),
      attributeType: Yup.object().required('Attribute Type is required'),
      attributeMasterId: Yup.object().required('Attribute Master is required'),
      order: Yup.string().required('SlugUrl is required')
   });
};
export const createCategoryAttributeMapSchema = () => {
   return Yup.object().shape({
      catId: Yup.object().required('Attribute Type is required'),
      attributeMasterId: Yup.object().required('Attribute Master is required')
   });
};
export const createPincodeMasterSchema = () => {
   return Yup.object().shape({
      pincode: Yup.string().required('Pincode is required'),
      city: Yup.string().required('city is required'),
      state: Yup.string().required('state is required'),
      county: Yup.string().required('county is required')
   });
};

export const createOrderAssignRiderSchema = () => {
   return Yup.object().shape({
      riderId: Yup.object().required('Rider is required')
   });
};

export const createDeliveryPincodeSchema = () => {
   return Yup.object().shape({
      pincodeMasterId: Yup.array().required('Pincode is required'),
      storeId: Yup.object().required('Store is required')
   });
};

export const createRepairingSchema = () => {
   return Yup.object().shape({
      userId: Yup.number().required('Customer is required'),
      productType: Yup.string().required('Product Type is required'),
      makeModal: Yup.string().required('Make Modal is required'),
      imeiNo: Yup.string().required('IMEI Number is required'),
      network: Yup.string().required('Network is required'),
      checklist: Yup.array().required('Checklist is required'),
      estimateCost: Yup.number().required('Estimate Cost is required'),
      depositPaid: Yup.number().required('Deposit Paid is required')
   });
};
